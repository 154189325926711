import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseApp } from './firebase-app';
import { GlobalGameStats, HumanGameStats, LlmGameStats } from "../common/global-game-stats";
import { LlmModelName } from "../common/global-enums";
import { LlmPlayerResult } from "../common/llm-player-result";
import { FrequentlyAskedQuestion, FrequentlyAskedQuestionDto } from "../common/frequently-asked-question";
import { Hint, HintDto } from "../common/hint";

/* Represents what's sent in the request to the Cloud function. */
interface FunctionRequest {
    gameId: string;
    userId: string;
}

/* Represents what's returned in the response from the Cloud function. */
interface FunctionResponse {
    word: string;
    theme: string;
    dailyWordNumber: number;
    humanGameStats: any;
    llmGameStats: any;
    faq: FrequentlyAskedQuestionDto[];
    hints: HintDto[];
}

export type GameResult = {
    secretWord: string;
    theme: string;
    dailyWordNumber: number;
    globalGameStats: GlobalGameStats;
    faq: FrequentlyAskedQuestion[];
    hints: Hint[];
};

/** 
 * Calls the Cloud function to get the game result.
 */
export function getGameResult(gameId: string, userId: string): Promise<GameResult> {
    const functions = getFunctions(getFirebaseApp());
    // Connect to local functions when running locally.
    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "localhost", 5001);
    }
    const getGameResult = httpsCallable<FunctionRequest, FunctionResponse>(functions, 'getGameResult');
    return getGameResult({ gameId, userId }).then((result) => {
        const response = result.data;
        const humanResultCountMap: Map<number, number> = new Map();
        Object.keys(response.humanGameStats.resultCountMap).forEach((key) => {
            humanResultCountMap.set(Number(key), response.humanGameStats.resultCountMap[key]);
        });
        const humanGameStats = new HumanGameStats(humanResultCountMap);
        const llmPlayerSummary: Map<LlmModelName, LlmPlayerResult[]> = new Map();
        Object.keys(response.llmGameStats).forEach((key) => {
            llmPlayerSummary.set(key as LlmModelName, response.llmGameStats[key]);
        });
        const llmGameStats = new LlmGameStats(llmPlayerSummary);
        return {
            secretWord: response.word,
            theme: response.theme,
            dailyWordNumber: response.dailyWordNumber,
            globalGameStats: new GlobalGameStats(humanGameStats, llmGameStats),
            faq: response.faq?.map((faqDto: FrequentlyAskedQuestionDto) => FrequentlyAskedQuestion.fromDto(faqDto)) ?? [],
            hints: response.hints?.map((hintDto: HintDto) => Hint.fromDto(hintDto)) ?? [],
        };
    }).catch((error) => {
        console.log("Error while getting game result from endpoint: " + error);
        throw error;
    });
}

