import { useContext, useLayoutEffect, useRef, useState } from 'react';
import '../../styles/GameStatsHeader.scss';

import { Col, Container, Image, Row } from "react-bootstrap";
import { GlobalStateContext, IGlobalStateContextValue } from '../../context/GlobalStateContext';

function GameStatsHeader() {
    const {
        globalGameStats
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;
    const areHumansWinning = globalGameStats!.getHumanGameStats().getScore() > globalGameStats!.getLlmGameStats().getScore();
    const areLlmsWinning = globalGameStats!.getLlmGameStats().getScore() > globalGameStats!.getHumanGameStats().getScore();

    const scoreHeaderRef = useRef<HTMLDivElement>(null);
    const [includeHeaderSpacer, setIncludeHeaderSpacer] = useState(false);
    useLayoutEffect(() => {
        const observer = new ResizeObserver(_entries => {
            setIncludeHeaderSpacer((scoreHeaderRef.current?.clientWidth ?? 0) < 310);
        });
        observer.observe(scoreHeaderRef.current!);
        return () => scoreHeaderRef.current! && observer.unobserve(scoreHeaderRef.current!);
    }, []);

    return <Container>
        <Row>
            <div className="text-center fs-5" ref={scoreHeaderRef}>Quest10n Scores</div>
            {includeHeaderSpacer && <div className="mb-4"></div>}
        </Row>
        <Row className="mt-2">
            <Col>
                {areHumansWinning &&
                    <div className="d-flex justify-content-center">
                        <Image className='crown' src={process.env.REACT_APP_PUBLIC_URL + 'crown.png'} />
                    </div>
                }
                <Row className="d-flex justify-content-center">
                    <div className="rounded-circle spotlight-circle">
                        <span>{globalGameStats!.getHumanGameStats().getScore().toFixed(0)}</span>
                    </div>
                </Row>
                <Row>
                    <div className="text-center">Humans</div>
                </Row>
            </Col>
            <Col className="d-flex justify-content-center align-items-center text-secondary fs-5 fw-bold">
                VS
            </Col>
            <Col>
                {areLlmsWinning &&
                    <div className="d-flex justify-content-center">
                        <Image className='crown' src={process.env.REACT_APP_PUBLIC_URL + 'crown.png'} />
                    </div>
                }
                <Row className="d-flex justify-content-center">
                    <div className="rounded-circle spotlight-circle">
                        <span>{globalGameStats!.getLlmGameStats().getScore().toFixed(0)}</span>
                    </div>
                </Row>
                <Row>
                    <div className="text-center">AI</div>
                </Row>
            </Col>
        </Row>
    </Container>;
}

export default GameStatsHeader;