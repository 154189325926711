import { Button, Col, Row } from "react-bootstrap";
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL } from "../../common/display-utils";

function DailyGamesBanner() {
    return (
        <Row className="justify-content-sm-center">
            <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
                <Button variant="dark" size="lg" className="w-100" href={process.env.REACT_APP_PUBLIC_URL + "dailyGames"}>Or browse the previous daily quests</Button>
            </Col>
        </Row>
    );
}

export default DailyGamesBanner;