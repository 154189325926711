import '../../styles/UserGameResultButton.scss';

import { Button } from "react-bootstrap";
import { GameWithUserResult } from "../../common/game-with-user-result";
import { UserGameResult } from "../../common/global-enums";

type Props = {
    gameWithUserResult: GameWithUserResult;
    stretchedLink: boolean;
};

// Button that shows the user's result for the game.
function UserGameResultButton(props: Props) {
    let className = "result-btn";
    if (props.stretchedLink) {
        className += " stretched-link";
    }
    if (props.gameWithUserResult.getUserGameResult() === UserGameResult.NOT_PLAYED) {
        return <Button
            className={className}
            variant="outline-dark"
            size="sm"
            href={process.env.REACT_APP_PUBLIC_URL + "game/" + props.gameWithUserResult.getGame().getGameId()}
            target="_blank">
            UNPLAYED
        </Button>;
    }
    if (props.gameWithUserResult.getUserGameResult() === UserGameResult.WON) {
        return <Button
            className={className}
            variant="outline-success"
            size="sm"
            href={process.env.REACT_APP_PUBLIC_URL + "game/" + props.gameWithUserResult.getGame().getGameId()}
            target="_blank">
            WON IN {props.gameWithUserResult.getNumQuestionsWon()}
        </Button>;
    }
    return <Button
        className={className}
        variant="outline-danger"
        size="sm"
        href={process.env.REACT_APP_PUBLIC_URL + "game/" + props.gameWithUserResult.getGame().getGameId()}
        target="_blank">
        LOST
    </Button>;
}

export default UserGameResultButton;
