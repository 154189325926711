import '../../styles/FinishedSummaryCard.scss'

import { Alert, Badge, Button, Card, Col, Form, Image, Row, Table } from "react-bootstrap";
import { useContext } from "react";
import { GlobalStateContext, IGlobalStateContextValue } from "../../context/GlobalStateContext";
import { capitalizeFirstLetter } from "../../common/word-utils";
import { GameState, LlmModelName, isLlmPlayer } from "../../common/global-enums";
import { modelToIconMap, modelToSpecificNameMap } from "../../common/llm-player-display-utils";
import Plural from "../shared/Plural";
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL, getFormattedDateForGameId } from '../../common/display-utils';

type Props = {
    enableSettings: boolean;
    enableSpoilerFreeMode?: boolean;
    onSpoilerFreeModeChangedCallback?: (enabled: boolean) => void
};

function FinishedSummaryCard(props: Props) {
    const {
        gameId,
        gameState,
        numQuestionsAsked,
        playerUserId,
        secretWord,
        theme,
        globalGameStats,
        dailyWordNumber,
        hints,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;
    const formattedDate = getFormattedDateForGameId(gameId);

    return (
        <Row className="justify-content-sm-center">
            <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
                <Card>
                    <Card.Header>
                        <div className="text-center fw-bold">
                            {gameState === GameState.NOT_STARTED &&
                                <span>Quest{dailyWordNumber > 0 && <> #{dailyWordNumber} ({formattedDate})</>}{dailyWordNumber === 0 && <> {gameId}</>}</span>
                            }
                            {gameState === GameState.COMPLETED &&
                                <span className="text-success">Quest{dailyWordNumber > 0 && <> #{dailyWordNumber}</>} Complete in {numQuestionsAsked} <Plural pluralText="Questions" singularText="Question" num={numQuestionsAsked} /></span>
                            }
                            {gameState === GameState.FAILED &&
                                <span className="text-danger">Quest{dailyWordNumber > 0 && <> #{dailyWordNumber}</>} Incomplete</span>
                            }
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Table bordered size="sm" className="mb-0">
                            <tbody>
                                <tr>
                                    <td align="right" className="text-primary fw-bold">
                                        Secret Word
                                    </td>
                                    <td className={props.enableSpoilerFreeMode ? "hidden-spoiler" : ""}>
                                        {!props.enableSpoilerFreeMode && capitalizeFirstLetter(secretWord)}
                                    </td>
                                </tr>
                                <tr>
                                    <td align="right" className="text-primary fw-bold">
                                        Theme
                                    </td>
                                    <td>
                                        {capitalizeFirstLetter(theme)}
                                    </td>
                                </tr>
                                {isLlmPlayer(playerUserId) &&
                                    <tr>
                                        <td align="right" className="text-primary fw-bold">
                                            AI Player
                                        </td>
                                        <td>
                                            <Image className="llm-icon-small" src={process.env.REACT_APP_PUBLIC_URL + modelToIconMap.get(playerUserId as LlmModelName)!}></Image> {modelToSpecificNameMap.get(playerUserId as LlmModelName)}
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <td align="right" className="text-primary fw-bold">
                                        AI Average # Questions to Win
                                    </td>
                                    <td>
                                        {globalGameStats!.getLlmGameStats().getAverageQuestionsToWin()}
                                    </td>
                                </tr>
                                {hints.length === 2 &&
                                    <>
                                        <tr>
                                            <td colSpan={2} align="center" className={props.enableSpoilerFreeMode ? "hidden-spoiler" : ""}>
                                                <div><span className={(props.enableSpoilerFreeMode ? "text-white" : "text-primary") + " fw-bold"}>Hint 1</span>{!props.enableSpoilerFreeMode && <>: {capitalizeFirstLetter(hints[0].getQuestion())}</>}</div>
                                                {!props.enableSpoilerFreeMode && <>
                                                    {hints[0].getChoices().map((choice, index) => (
                                                        <span key={index}>
                                                            <Badge
                                                                bg={hints[0].getAnswer() === choice ?
                                                                    "success" : "none"}
                                                                className="mx-1 transcript-hint-default-choice">
                                                                {choice}
                                                            </Badge>
                                                        </span>
                                                    ))}
                                                    <div>Bonus info: {hints[0].getBonusInfo()}</div>
                                                </>}
                                            </td>
                                        </tr>
                                        <tr className={props.enableSpoilerFreeMode ? "hidden-spoiler" : ""}>
                                            <td colSpan={2} align="center" className={props.enableSpoilerFreeMode ? "hidden-spoiler" : ""}>
                                                <div><span className={(props.enableSpoilerFreeMode ? "text-white" : "text-primary") + " fw-bold"}>Hint 2</span>{!props.enableSpoilerFreeMode && <>: {capitalizeFirstLetter(hints[1].getQuestion())}</>}</div>
                                                {!props.enableSpoilerFreeMode && <>
                                                    {hints[1].getChoices().map((choice, index) => (
                                                        <span key={index}>
                                                            <Badge
                                                                bg={hints[1].getAnswer() === choice ?
                                                                    "success" : "none"}
                                                                className="mx-1 transcript-hint-default-choice">
                                                                {choice}
                                                            </Badge>
                                                        </span>
                                                    ))}
                                                    <div>Bonus info: {hints[1].getBonusInfo()}</div>
                                                </>}
                                            </td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </Table>
                        {(gameState === GameState.COMPLETED || gameState === GameState.FAILED) &&
                            <div className="mt-3 d-flex justify-content-center">
                                <Button variant="primary" href={process.env.REACT_APP_PUBLIC_URL + "gameResult/" + gameId} target="_blank">View Full Game Results</Button>
                            </div>
                        }
                        {props.enableSettings &&
                            <div className="mt-3 d-flex justify-content-center">
                                <Alert variant={props.enableSpoilerFreeMode ? "primary" : "light"} className="d-flex justify-content-center">
                                    <div className="w-100">
                                        <Form.Check
                                            id="spoiler-free"
                                            type="switch"
                                            label="Spoiler-free mode"
                                            reverse={true}
                                            checked={props.enableSpoilerFreeMode}
                                            onChange={() => props.onSpoilerFreeModeChangedCallback!(!props.enableSpoilerFreeMode)} />
                                    </div>
                                </Alert>
                            </div>
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default FinishedSummaryCard;