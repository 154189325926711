import React from 'react';
import { useMemo, useState } from 'react';
import { GameState } from '../common/global-enums';
import { AskedQuestion } from '../common/asked-question';
import { UserInfo } from '../common/user-info';
import { Hint } from '../common/hint';
import { GameTranscriptRecord, GameTranscriptRecordType } from '../common/game-transcript-record';
import { GlobalGameStats } from '../common/global-game-stats';
import { FrequentlyAskedQuestion } from '../common/frequently-asked-question';

interface IGlobalStateProviderProps {
    children: React.ReactNode,
    acceptedVariants: string[],
    acceptedThemeWords: string[],
    faq: FrequentlyAskedQuestion[],
    globalGameStats: GlobalGameStats | undefined,
    gameId: string,
    secretWord: string,
    playerUserId: string,
    initialGameState: GameState,
    initialGameTranscriptRecords: GameTranscriptRecord[],
    initialHints: Hint[],
    initialGameSessionId: string,
    initialUserInfo: UserInfo,
    initialCurrentWinStreak: number,
    theme: string,
    dailyWordNumber: number,
}

export interface IGlobalStateContextValue {
    secretWord: string,
    gameId: string,
    acceptedVariants: string[],
    acceptedThemeWords: string[],
    faq: FrequentlyAskedQuestion[],
    globalGameStats: GlobalGameStats | undefined,
    gameState: GameState,
    setGameState: React.Dispatch<React.SetStateAction<GameState>>,
    numQuestionsAsked: number,
    numAttemptsAllowed: number,
    showHintsAfterNumQuestions: number,
    validAskedQuestions: AskedQuestion[],
    gameTranscriptRecords: GameTranscriptRecord[],
    setGameTranscriptRecords: React.Dispatch<React.SetStateAction<GameTranscriptRecord[]>>,
    hints: Hint[],
    questionResponseMessage: string,
    setQuestionResponseMessage: React.Dispatch<React.SetStateAction<string>>,
    gameSessionId: string,
    setGameSessionId: React.Dispatch<React.SetStateAction<string>>,
    userInfo: UserInfo,
    setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>,
    showSignInModal: boolean,
    setShowSignInModal: React.Dispatch<React.SetStateAction<boolean>>,
    showTutorialModal: boolean,
    setShowTutorialModal: React.Dispatch<React.SetStateAction<boolean>>,
    currentWinStreak: number,
    setCurrentWinStreak: React.Dispatch<React.SetStateAction<number>>,
    theme: string,
    dailyWordNumber: number,
    playerUserId: string,
}

export const GlobalStateContext = React.createContext<IGlobalStateContextValue | undefined>(undefined);

const GlobalStateProvider = ({
    children,
    acceptedVariants = [],
    acceptedThemeWords = [],
    faq,
    globalGameStats,
    gameId,
    secretWord,
    theme,
    dailyWordNumber,
    playerUserId,
    initialGameState,
    initialGameTranscriptRecords,
    initialHints,
    initialGameSessionId,
    initialUserInfo,
    initialCurrentWinStreak,
}: IGlobalStateProviderProps) => {
    const [gameState, setGameState] = useState(initialGameState);
    const [gameTranscriptRecords, setGameTranscriptRecords] = useState<GameTranscriptRecord[]>(initialGameTranscriptRecords);
    const [gameSessionId, setGameSessionId] = useState(initialGameSessionId);
    const [questionResponseMessage, setQuestionResponseMessage] = useState("");
    const [userInfo, setUserInfo] = useState<UserInfo>(initialUserInfo);
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false);
    const [currentWinStreak, setCurrentWinStreak] = useState(initialCurrentWinStreak);
    const validAskedQuestions = useMemo(() =>
        gameTranscriptRecords
            .filter(record => record.getType() === GameTranscriptRecordType.AskedQuestion)
            .map(record => record.getAskedQuestion()), [gameTranscriptRecords]);
    const numQuestionsAsked = useMemo(() => validAskedQuestions.length, [validAskedQuestions]);

    const value = useMemo(() => ({
        acceptedVariants,
        acceptedThemeWords,
        faq,
        gameId,
        gameState,
        globalGameStats,
        numQuestionsAsked,
        numAttemptsAllowed: 10,
        showHintsAfterNumQuestions: 4,
        secretWord,
        theme,
        dailyWordNumber,
        playerUserId,
        questionResponseMessage,
        validAskedQuestions,
        gameTranscriptRecords,
        hints: initialHints,
        gameSessionId,
        userInfo,
        showSignInModal,
        showTutorialModal,
        currentWinStreak,
        setGameState,
        setQuestionResponseMessage,
        setGameTranscriptRecords,
        setGameSessionId,
        setUserInfo,
        setShowSignInModal,
        setShowTutorialModal,
        setCurrentWinStreak,
    }), [gameState, numQuestionsAsked, gameTranscriptRecords, questionResponseMessage, gameSessionId, userInfo, showSignInModal, showTutorialModal, currentWinStreak]);

    return (
        <GlobalStateContext.Provider value={value}>
            {children}
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateProvider;