import { Carousel, Col, Row } from "react-bootstrap";
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL } from "../../common/display-utils";
import { GameWithUserResult } from "../../common/game-with-user-result";
import GameCard from "./GameCard";

type Props = {
    games: GameWithUserResult[]
};

function PopularGamesSection(props: Props) {
    return (
        <Row className="justify-content-sm-center">
            <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
                <h4 className="text-primary">Popular Custom Games</h4>
                <Carousel controls={true} indicators={true} variant="dark" className="mt-4" interval={null}>
                    {props.games.map((game, idx) => (
                        <Carousel.Item key={idx}>
                            <div className="d-flex justify-content-center mb-5">
                                <GameCard game={game} />
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Col>
        </Row>
    );
}

export default PopularGamesSection;